/**
 * Implements the tooltip hover behaviour for the glossary items
 *
 * Originally taken from Ulm-Augsburg
 */

if (window.tooltipIdentifier === undefined) {
    const tooltipIdentifier = 'data-glossary';
    const tooltips = document.querySelectorAll('[' + tooltipIdentifier + ']');
    let someToolTipOpen = null;

    const tooltipHelper = (tooltip) => {
        if (tooltip.classList.contains('is-open')) return;
        tooltip.classList.add('is-open');

        const aBounds = tooltip.getBoundingClientRect();
        const a = {
            x: aBounds.x,
            w: aBounds.width,
        };

        const tooltipContainer = tooltip.childNodes[1];
        const tooltipContent = tooltipContainer.childNodes[0];
        const cssObj = window.getComputedStyle(tooltipContainer, null);

        let cWidth = parseInt(cssObj.getPropertyValue('width'));
        let cHeight = parseInt(cssObj.getPropertyValue('height'));

        if (a.x + cWidth >= Math.min(document.documentElement.clientWidth, window.innerWidth) - 40) {
            if (a.x + a.w - cWidth <= 40) {
                //=> centrierte darstell
                tooltipContainer.style.setProperty('right', 'unset', 'important');
                tooltipContainer.style.setProperty('left', '50%', 'important');
                tooltipContainer.style.setProperty('transform', 'translate(-50%,0)', 'important');
                tooltipContent.className = 'tooltip__content center';
            } else {
                //=> rechts bündige darstellung!
                tooltipContainer.style.setProperty('transform', 'unset', 'important');
                tooltipContainer.style.setProperty('left', 'unset', 'important');
                tooltipContainer.style.setProperty('right', '0', 'important');
                tooltipContent.className = 'tooltip__content right';
            }
        } else {
            //=> linksbündige darstellung
            tooltipContainer.style.setProperty('transform', 'unset', 'important');
            tooltipContainer.style.setProperty('right', 'unset', 'important');
            tooltipContainer.style.setProperty('left', '0', 'important');
            tooltipContent.className = 'tooltip__content left';
        }
    };

    tooltips.forEach((tooltip) => {
        tooltip.addEventListener('mouseover', function () {
            tooltipHelper(this);
        });
        tooltip.addEventListener('mouseleave', () => {
            tooltip.classList.remove('is-open');
        });
        tooltip.addEventListener('click', () => {
            closeToolTip(tooltip);
        });
    });

    window.addEventListener(
        'touchstart',
        function (event) {
            someToolTipOpen = document.querySelector('[' + tooltipIdentifier + '].is-open');

            let target;
            let openNew = true;

            if (
                event.target.getAttribute(tooltipIdentifier) ||
                event.target.parentElement.getAttribute(tooltipIdentifier)
            ) {
                if (event.cancelable) event.preventDefault();

                target = event.target.getAttribute(tooltipIdentifier) ? event.target : event.target.parentElement;

                if (someToolTipOpen) {
                    const thisToolTipIsOpen = target.classList.contains('is-open') ? true : false;

                    closeToolTip(someToolTipOpen);

                    if (thisToolTipIsOpen) openNew = false;
                }

                if (openNew) openToolTip(target);
            } else {
                if (someToolTipOpen) {
                    if (event.target.classList.contains('tooltip__content')) {
                        if (event.cancelable) {
                            event.preventDefault();
                        }
                        event.stopPropagation();
                        return;
                    }
                    if (event.target.classList.contains('tooltip__link')) {
                        if (event.cancelable) event.preventDefault();
                        event.target.closest('a').click();
                        setTimeout(() => {
                            closeToolTip(someToolTipOpen);
                        }, 100);
                    } else closeToolTip(someToolTipOpen);
                }
            }
        },
        { passive: false }
    );

    const openToolTip = (target) => {
        tooltipHelper(target);
    };

    const closeToolTip = (target) => {
        target.classList.remove('is-open');
    };
}
